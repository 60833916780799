footer {
  background: #002b50;
  color: #fff;
  font-size: 14px;
  padding: 20px 0 20px 10%;
}

footer a {
  color: #fff;
  text-decoration: underline;
}
