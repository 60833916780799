.buttonWrapper {
}

.buttonWrapper button {
  display: inline-block;
  height: 45px;
  width: 180px;
  padding: 0 32px;
  color: #fff;
  text-align: center;
  font-size: 16px;
  line-height: 45px;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  box-sizing: border-box;
  margin:6px;
  border: 1px solid #fff;
}
