
#welcome {
  height: calc(100vh - 107px);
  display: flex;
  align-items: stretch;
}

#welcome>div {
  flex: 1;
}

#welcome .left {
  text-align: center;
  align-self: center;
}

#welcome .right {
  box-sizing: border-box;
  background: rgba(60, 60, 200, .3);
  align-self: end;
  padding: 20px 0% 20px 0px;
  color: #fff;
  background: rgb(255, 255, 255);
  background: linear-gradient(120deg, rgba(0, 87, 160, .85) 0%, rgba(0, 87, 160, .85) 70%, rgba(255, 255, 255, 0) 70%);
  line-height: 1.2;
}

#welcome .right > div {
  padding: 10px 30% 5px 30px;
}

#welcome .learn-more {
  max-width: 130px;
  width: 35%;
}

.fs4{ font-size: 2.5rem;}
.fs17{font-size: 1.3rem;}
.fs19{font-size: 1.3rem;}
.fs22{font-size: 1.5rem;}

.fw2{font-weight: 200;}
.fw3{font-weight: 300;}
.fw6{font-weight: 600;}
.fw8{font-weight: 800;}

.text-darkblue{color: #002b50;}
.text-blue{color: #0057a0;}


.voffset2 {margin-top: 10px;}
.voffset3 {margin-top: 15px;}
.voffset5 {margin-top: 40px;}

.button-row {
  display: flex;
  justify-content: center;
}


/* Larger than phablet (also point when grid becomes active) */
@media (min-width: 550px) {
  .hide-desktop{display: none;}
}

@media (max-width: 550px) {
  .hide-mobile{display: none;}
  .fs22{font-size: 1.6rem;}
  .fs3{ font-size: 7vw;}
  .fs6{ font-size: 12vw;}
  #welcome{flex-direction: column; height: 100%;}
}

@media (min-width: 550px) {
  #welcome {
    background: url(./resources/welcome.jpg) no-repeat 50vw 0% / auto 100%;
    Overflow: hidden;
  }
}
