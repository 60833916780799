.tool {
  width: 20%;
  min-width: 160px;
  margin: 0 2.4% 2%;
  cursor: pointer;
}

.wrap>* {
  grid-area: 1 / 1 / 2 / 2;
}

.wrap {
  background: #80abd0;
  border-radius: 15px;
  display: grid;
  margin: 5%;
  text-align: center;
  position: relative;
  min-height: 90px;
  aspect-ratio: 12/9;
  box-shadow: 0 10px 5px -10px #aaa;
  transition: all .3s ease;
}

.wrap img {
  max-width: 60%;
  max-height: 65%;
  position: absolute;
  inset: 0;
  margin: auto;
  transition: all .3s ease;
}

.title {
  color: #242424;
  font-size: .9rem;
  line-height: 1.35;
}