.headerDividerContainer {
  width: 100%;
  height: 40px;
  background-color: #ff9900;
  position: relative;
  overflow: hidden;
}

.headerDividerBox {
  position: absolute;
  height: 40px;
  width: 270px;
  background-color: #ffffff;
  bottom: -30px;
  transform: rotate(-50deg);
  right: -100px;
}

.llsLogoImage {
  width: 200px!important;
}

.headerDiv {
}

.headerDiv header {
  display: flex;
  background: rgb(250, 155, 14);
  height: 48px;
}

.loginService {
  margin-left: auto;
  margin-right: 5%;
  margin-top: 5px;
}

.logo {
  margin-left: 10%;
  position: relative;
}

.logo img {
  width: 200px;
  max-width: 160px;
  margin: 20px 0;
}

@media only screen and (max-width: 600px) {
  .headerDividerContainer {
    display: none;
  }
  .llsLogoImage {
    width: 100px;
  }
}

//small & medium screen rules
@media only screen and (min-width: 600px) {
  .headerDividerContainer {
    height: 20px;
  }
  .llsLogoImage {
    width: 150px;
  }
}

//large screen rules
@media only screen and (min-width: 700px) {
  .headerDividerContainer {
    height: 25px;
  }
  .llsLogoImage {
    width: 200px;
  }
}

//X-large screen rules
@media only screen and (min-width: 1000px) {
  .headerDividerContainer {
    height: 30px;
  }
  .llsLogoImage {
    width: 250px;
  }
}
